import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types";

function HeroImg({lang}) {

    const data = useStaticQuery(graphql`
    query  {
      largeImg: file(relativePath: {eq: "top.jpg"}) {
          childImageSharp {
          gatsbyImageData(

               placeholder: BLURRED
               formats: [AUTO, WEBP]
               layout: FULL_WIDTH

               )
          }
      }
      smallImg: file(relativePath: {eq: "topsm.jpg"}) {
          childImageSharp {
              gatsbyImageData(

               placeholder: BLURRED
               formats: [AUTO, WEBP]
               layout: FULL_WIDTH
              )
         }
     }
     smallImgEn: file(relativePath: {eq: "topsmEn.jpg"}) {
      childImageSharp {
          gatsbyImageData(

           placeholder: BLURRED
           formats: [AUTO, WEBP]
           layout: FULL_WIDTH
          )
     }
 }
  }

  `)

  const images =  (lang === 'ja')  ? withArtDirection(getImage(data.smallImg.childImageSharp.gatsbyImageData),
   [
    {
      media: "(min-width: 768px)",
      image: getImage(data.largeImg.childImageSharp.gatsbyImageData),
    },
  ])
  : withArtDirection(getImage(data.smallImgEn.childImageSharp.gatsbyImageData),
  [
   {
     media: "(min-width: 768px)",
     image: getImage(data.largeImg.childImageSharp.gatsbyImageData),
   },
 ])
;

  return <GatsbyImage 
  className="art-directed" 
  image={ images } 
  alt=
  { (lang === 'ja')  ?
  "全身調整をしてスッキリ目覚める朝を迎えましょう"
: "Your osteopath in Nishiogikubo, Suginami-ku"
}
  loading="eager" />
};

HeroImg.defaultProps = {
  lang: `ja`,
};

HeroImg.propTypes = {
  lang: PropTypes.string,
};

export default HeroImg;